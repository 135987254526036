import {
  Avatar,
  Box,
  Button,
  Flex,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Spacer,
  Spinner,
  Text,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import axios from "./utils/axios";
import { useNavigate } from "react-router-dom";
import { useAuth } from "./components/AuthContext";

const Banner = () => {
  let auth = useAuth();
  const navigate = useNavigate();
  const [message, setMessage] = useState("Checking Available Space");
  const [isLoading, setIsLoading] = useState(true);

  return (
    <Flex
      bg="blue.400"
      py={1}
      display={"flex"}
      justifyContent={"flex-end"}
      alignItems={"center"}
      position={"fixed"}
      top={0}
      zIndex={10}
      w={"100%"}
    >
      <Button
        ml={"20px"}
        onClick={() => {
          const contactFormName = document.getElementById("contact-form-name");
          const contactForm = document.getElementById("contact-form");
          if (contactForm && contactFormName) {
            contactForm.scrollIntoView();
            contactFormName.focus();
          }
        }}
      >
        Sign Up
      </Button>
      <Spacer />
      <Text fontSize={35} fontWeight={600} color={'white'}>
        Down For Maintenance
      </Text>
      <Spacer />
      <Box pr={"20px"}>
        {!!auth.user && (
          <Popover>
            <PopoverTrigger>
              <div style={{ cursor: "pointer" }}>
                <Avatar
                  name={auth.user.email}
                  src="https://bit.ly/broken-link"
                />
              </div>
            </PopoverTrigger>
            <PopoverContent w={"200px"}>
              <PopoverCloseButton />
              <PopoverBody
                display={"flex"}
                justifyContent={"end"}
                mt={7}
                flexDirection={"column"}
              >
                <Button
                  onClick={() => {
                    navigate("/account");
                  }}
                >
                  Account
                </Button>
                <Button
                  variant={"ghost"}
                  mt={3}
                  onClick={() => auth.signOut(() => {})}
                >
                  Log Out
                </Button>
              </PopoverBody>
            </PopoverContent>
          </Popover>
        )}
        {!auth.user && (
          <Button
            onClick={() => {
              navigate("/login");
            }}
          >
            Login
          </Button>
        )}
      </Box>
    </Flex>
  );
};
export default Banner;
