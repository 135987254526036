import { ReactElement } from "react";
import {
  Box,
  SimpleGrid,
  Icon,
  Text,
  Stack,
  Flex,
  Heading,
} from "@chakra-ui/react";
import { FcPhone, FcDonate, FcEndCall } from "react-icons/fc";

interface FeatureProps {
  title: string;
  text: string;
  icon: ReactElement;
}

const Feature = ({ title, text, icon }: FeatureProps) => {
  return (
    <Stack alignItems={"center"}>
      <Flex
        w={16}
        h={16}
        align={"center"}
        justify={"center"}
        color={"white"}
        rounded={"full"}
        bg={"gray.100"}
        mb={1}
      >
        {icon}
      </Flex>
      <Text fontWeight={600}>{title}</Text>
      <Text color={"gray.600"}>{text}</Text>
    </Stack>
  );
};

export default function SimpleThreeColumns() {
  return (
    <Box maxW={"1280"} margin={"auto"}>
      <Heading
        lineHeight={1.1}
        fontWeight={600}
        fontSize={{ base: "3xl", sm: "5xl", lg: "6xl" }}
        textAlign={"center"}
        paddingBottom={"40px"}
      >
        How It Works
      </Heading>
      <SimpleGrid mx={5} columns={{ base: 1, md: 3 }} spacing={10}>
        <Feature
          icon={<Icon as={FcPhone} w={10} h={10} />}
          title={"Sign up with your phone number"}
          text={
            "By signing up with your mobile number (US only) we can delivery positive messages directly to your device by text message"
          }
        />
        <Feature
          icon={<Icon as={FcDonate} w={10} h={10} />}
          title={"The price is right"}
          text={
            "For 5 cents a day you can get the right message at the right time. Way less than that cup of coffee you bought this morning."
          }
        />
        <Feature
          icon={<Icon as={FcEndCall} w={10} h={10} />}
          title={"Cancel any time"}
          text={
            "If it's not your style or you don't see value, you can cancel any time."
          }
        />
      </SimpleGrid>
    </Box>
  );
}
