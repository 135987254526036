import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  Textarea,
  useDisclosure,
  useToast,
  VStack,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { IUser, useAuth } from "../components/AuthContext";
import { useNavigate } from "react-router-dom";
import axios from "../utils/axios";

const sendToAll = async ({
  message,
  key,
}: {
  message: string;
  key: string;
}) => {
  return axios.post("/api/admin/sendCustomMessageToAll", {
    customMessage: message,
    key,
  });
};

const AdminPage = () => {
  let navigate = useNavigate();
  let { user, updateUser } = useAuth();
  const toast = useToast();
  let [users, setUsers] = useState<IUser[]>([]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [message, setMessage] = useState("");
  const [key, setKey] = useState("");

  useEffect(() => {
    if (!user) {
      return navigate("/");
    }
    if (user && user.role !== "admin") {
      return navigate("/account");
    } else {
      axios
        .get("/api/admin/users")
        .then(({ data }) => {
          setUsers(data);
        })
        .catch((e) => {
          toast({
            position: "top",
            title: "Well that didn't work",
            description: e?.response?.data?.message,
            status: "error",
            duration: 9000,
            isClosable: true,
          });
        });
    }
  }, [user]);

  if (!user) return null;

  return (
    <VStack
      spacing={5}
      bg={"white"}
      m={10}
      p={10}
      maxW={800}
      borderRadius="lg"
      position={"relative"}
    >
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Send Message To All</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl variant="floating" isRequired>
              <Textarea
                onChange={(e) => setMessage(e.target.value)}
                value={message}
              />
              <FormLabel>Message</FormLabel>
            </FormControl>
            <FormControl variant="floating" isRequired mt={5}>
              <Input
                type={"password"}
                onChange={(e) => setKey(e.target.value)}
                value={key}
              />
              <FormLabel>Key</FormLabel>
            </FormControl>
          </ModalBody>

          <ModalFooter>
            <Button
              colorScheme="blue"
              mr={3}
              onClick={() => {
                sendToAll({ message, key })
                  .then(() => {
                    onClose();
                  })
                  .catch((e) => {
                    toast({
                      position: "top",
                      title: "Well that didn't work",
                      description: e?.response?.data?.message,
                      status: "error",
                      duration: 9000,
                      isClosable: true,
                    });
                  });
              }}
            >
              Send To All
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Box w={"100%"}>
        <Button onClick={onOpen}>Send Message to All</Button>
      </Box>
      <Box>
        <Text>User Actions</Text>
        {users.map((user) => {
          return (
            <Flex>
              <Text>{user.email}</Text>
              {/*<Button>Send Message</Button>*/}
            </Flex>
          );
        })}
      </Box>
    </VStack>
  );
};

export default AdminPage;
