export const paymentConfiguration = {
  production: {
    yearly: {
      price: "$19.99",
      description: "year",
      link: "https://buy.stripe.com/00g2bC5NDgA17ew28a",
    },
    monthly: {
      price: "$4.99",
      description: "month",
      link: "https://buy.stripe.com/28o03u0tjerTeGY7st",
    },
  },
  development: {
    yearly: {
      price: "$19.99",
      description: "year",
      link: "https://buy.stripe.com/00g2bC5NDgA17ew28a",
    },
    monthly: {
      price: "$4.99",
      description: "month",
      link: "https://buy.stripe.com/28o03u0tjerTeGY7st",
    },
    // yearly: {
    //   price: 14.99,
    //   description: "year",
    //   link: "https://buy.stripe.com/test_bIYcMS3xO0JQaQw9AD",
    // },
    // monthly: {
    //   price: 4.99,
    //   description: "month",
    //   link: "https://buy.stripe.com/test_3cs28e8S8akq1fWdQU",
    // },
  },
};

export const getPaymentConfiguration = (): any => {
  // @ts-ignore
  const env = process.env.NODE_ENV;
  // @ts-ignore
  return paymentConfiguration[env];
};
