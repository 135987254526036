import { useSearchParams } from "react-router-dom";
import { Flex, Text, useBreakpointValue } from "@chakra-ui/react";
import "./styles.css";
const AffirmationPage = () => {
  let [searchParams, setSearchParams] = useSearchParams();
  const fontSizeVariant = useBreakpointValue({
    base: 45,
    md: 55,
  });
  const affirmation = searchParams.get("affirmation");
  const affirmationArray = affirmation?.split(" ");
  console.log({ fontSizeVariant });
  return (
    <Flex
      justifyContent={"center"}
      alignItems={"center"}
      h={"100vh"}
      w={"100vw"}
    >
      <Flex p={5} wrap={"wrap"} textAlign={"center"} justifyContent={"center"}>
        {affirmationArray?.map((word, index) => {
          let specialColor = word === "f*cking" ? "#cd2121" : "black";
          return (
            <Text
              key={index}
              as={"span"}
              className={"animateIn"}
              fontWeight={"bold"}
              fontSize={fontSizeVariant}
              pr={3}
              style={{ animationDelay: `${index * 0.1}s`, color: specialColor }}
            >
              {`${word} `}
            </Text>
          );
        })}
      </Flex>
    </Flex>
  );
};
export default AffirmationPage;
