import {
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputLeftAddon,
  InputLeftElement,
  Text,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { GiPartyPopper } from "react-icons/gi";
import { FiRefreshCcw } from "react-icons/fi";
import React, { useEffect, useState } from "react";
import { useAuth } from "../components/AuthContext";
import { useNavigate } from "react-router-dom";
import axios from "../utils/axios";
import { BsPerson } from "react-icons/bs";
import { MdOutlineEmail, MdOutlineVpnKey } from "react-icons/md";
import { GiNuclearBomb } from "react-icons/gi";
import { Select } from "chakra-react-select";
import MESSAGE_OPTIONS, {
  getMessageOptionFromValue,
} from "../utils/message.options";
import DELIVERY_TIME_OPTIONS, {
  getDeliveryTimeOptionFromValue,
} from "../utils/delivery.time.options";
import { getPaymentConfiguration } from "../utils/constants";

const AccountPage = () => {
  let navigate = useNavigate();
  let { user, updateUser } = useAuth();
  useEffect(() => {
    if (!user) {
      return navigate("/login");
    }
    setFullName(user.fullName);
    setPhoneNumber(user.phoneNumber);
    setEmail(user.email);
    setMessageOption(
      getMessageOptionFromValue(user.messageTypes[0]) ||
        MESSAGE_OPTIONS.SELF_AFFIRMATIONS
    );
    setUseSwearWords(user.useSwearWords);
    setDeliveryTimeOption(
      getDeliveryTimeOptionFromValue(user.deliveryTimeOptions) ||
        DELIVERY_TIME_OPTIONS.MORNING
    );
  }, [user]);
  const toast = useToast();
  const [fullName, setFullName] = useState(user?.fullName);
  const [phoneNumber, setPhoneNumber] = useState(user?.phoneNumber);
  const [email, setEmail] = useState(user?.email);
  const [password, setPassword] = useState("");
  const [messageOption, setMessageOption] = useState(
    getMessageOptionFromValue(user?.messageTypes[0]) ||
      MESSAGE_OPTIONS.SELF_AFFIRMATIONS
  );
  const [useSwearWords, setUseSwearWords] = useState(user?.useSwearWords);
  const [deliveryTimeOption, setDeliveryTimeOption] = useState(
    getDeliveryTimeOptionFromValue(user?.deliveryTimeOptions) ||
      DELIVERY_TIME_OPTIONS.MORNING
  );

  const [isSubmitting, setIsSubmitting] = useState(false);

  if (!user) return null;

  function handleSubmit(event: React.MouseEvent<HTMLButtonElement>) {
    if (!user) return;
    event.preventDefault();
    setIsSubmitting(true);
    axios
      .post(`/api/account/update/${user._id}`, {
        fullName,
        email,
        phoneNumber,
        messageTypes: [messageOption.value],
        deliveryTime: deliveryTimeOption.value,
        password,
        useSwearWords,
      })
      .then(({ data }) => {
        setIsSubmitting(false);
        updateUser(data);
        toast({
          title: "Account Updated",
          description: "Your account has been updated.",
          status: "success",
          duration: 9000,
          isClosable: true,
          position: "top",
        });
      })
      .catch((e) => {
        setIsSubmitting(false);
        console.log(e.response.data);
        const message =
          e?.response?.data?.message ||
          `Check your info and try again.  Fingers crossed`;
        toast({
          position: "top",
          title: "Well that didn't work",
          description: message,
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      });
  }

  const paymentConfiguration = getPaymentConfiguration();

  // This is just to appease the React gods.  The icon prop on Checkbox passes through an "isChecked" prop that makes
  // it all the way to the DOM and react yells
  const NuclearBombIcon = () => <GiNuclearBomb />;

  const Billing = ({
    pricingTier,
    billingPeriod,
  }: {
    pricingTier: string;
    billingPeriod: string;
  }): JSX.Element | null => {
    if (!user) return null;
    let content;
    const [isPaying, setIsPaying] = useState(false);
    switch (pricingTier.toUpperCase()) {
      case "LEGACY":
        content = (
          <VStack>
            <Flex>
              <Text mr={2} fontSize={45} fontWeight={800} color={"gray.700"}>
                Tier:
              </Text>
              <Text fontSize={45} fontWeight={800} color={"blue.400"}>
                LEGACY
              </Text>
            </Flex>
            <Text>You've been a legend from the start.</Text>
            <Flex alignItems={"center"}>
              <Text mr={2}>We're glad you're here.</Text>
              <GiPartyPopper size={30} color={"#4299e1"} />
            </Flex>
          </VStack>
        );
        break;
      case "GIFT":
        content = (
          <VStack>
            <Flex>
              <Text mr={2} fontSize={45} fontWeight={800} color={"gray.700"}>
                Tier:
              </Text>
              <Text fontSize={45} fontWeight={800} color={"blue.400"}>
                GIFT
              </Text>
            </Flex>
            <Text>Someone thinks you're awesome.</Text>
            <Flex alignItems={"center"}>
              <Text mr={2}>We're glad you're here.</Text>
              <GiPartyPopper size={30} color={"#4299e1"} />
            </Flex>
          </VStack>
        );
        break;
      case "BASIC":
        return (
          <VStack>
            <Flex>
              <Text mr={2} fontSize={40} fontWeight={800} color={"gray.700"}>
                Billing:
              </Text>
              <Text fontSize={40} fontWeight={800} color={"blue.400"}>
                {billingPeriod.toUpperCase()}
              </Text>
            </Flex>
            <Text fontWeight={700} color={"gray.700"}>
              To change or cancel your subscription email:
              happiermexyz@gmail.com
            </Text>
          </VStack>
        );
      default:
        content = (
          <VStack>
            <Flex>
              <Text mr={2} fontSize={45} fontWeight={800} color={"gray.700"}>
                Tier:
              </Text>
              <Text fontSize={45} fontWeight={800} color={"blue.400"}>
                NONE
              </Text>
            </Flex>
            <Text>Select a payment option to get started</Text>
            {isPaying && (
              <Button
                onClick={() => {
                  axios.get("/api/account/currentUser").then(({ data }) => {
                    updateUser(data);
                  });
                }}
              >
                Refresh Account
              </Button>
            )}

            {!isPaying && (
              <Flex alignItems={"center"} p={4}>
                <Flex>
                  {Object.keys(paymentConfiguration).map((key: any) => {
                    const config = paymentConfiguration[key];
                    return (
                      <Flex direction={"column"} p={2} key={key}>
                        <Text
                          mb={2}
                          fontSize={20}
                          fontWeight={800}
                          color={"gray.700"}
                        >
                          {config.price}/{config.description}
                        </Text>
                        <Button
                          bg="blue.400"
                          color="white"
                          _hover={{
                            bg: "blue.500",
                          }}
                          onClick={() => {
                            if (!user) return;
                            setIsPaying(true);
                            window.open(
                              `${config.link}?client_reference_id=${user._id}`,
                              "_blank"
                            );
                          }}
                        >
                          Subscribe
                        </Button>
                      </Flex>
                    );
                  })}
                </Flex>
              </Flex>
            )}
          </VStack>
        );
    }
    return <Box as={"div"}>{content}</Box>;
  };

  return (
    <VStack
      spacing={5}
      bg={"white"}
      m={10}
      p={10}
      maxW={800}
      borderRadius="lg"
      position={"relative"}
    >
      <FiRefreshCcw
        style={{ position: "absolute", top: 10, left: 10 }}
        onClick={() => {
          axios.get("/api/account/currentUser").then(({ data }) => {
            updateUser(data);
          });
        }}
      />
      <Billing
        pricingTier={user.pricingTier}
        billingPeriod={user.billingPeriod}
      />
      <FormControl isRequired>
        <FormLabel>Name</FormLabel>
        <InputGroup>
          <InputLeftElement children={<BsPerson />} />
          <Input
            value={fullName}
            type="text"
            name="name"
            id={"contact-form-name"}
            placeholder="Your Name"
            onChange={(e) => setFullName(e.target.value)}
          />
        </InputGroup>
      </FormControl>

      <FormControl isRequired>
        <FormLabel>Email</FormLabel>
        <InputGroup>
          <InputLeftElement children={<MdOutlineEmail />} />
          <Input
            value={email}
            type="email"
            name="email"
            placeholder="Your Email"
            onChange={(e) => setEmail(e.target.value)}
          />
        </InputGroup>
      </FormControl>

      <FormControl isRequired>
        <FormLabel>Password</FormLabel>
        <InputGroup>
          <InputLeftElement children={<MdOutlineVpnKey />} />
          <Input
            value={password}
            type="password"
            name="password"
            placeholder="**********"
            onChange={(e) => setPassword(e.target.value)}
          />
        </InputGroup>
      </FormControl>

      <FormControl isRequired>
        <FormLabel>Phone Number (US Only)</FormLabel>
        <InputGroup>
          <InputLeftAddon children="+1" />
          <Input
            value={phoneNumber}
            type="phone"
            name="phone"
            placeholder="Your Phone Number"
            onChange={(e) => setPhoneNumber(e.target.value)}
          />
        </InputGroup>
      </FormControl>
      <FormControl isRequired>
        <FormLabel>Kind of Message</FormLabel>
        <Select
          name="message-type"
          options={Object.values(MESSAGE_OPTIONS)}
          value={messageOption}
          closeMenuOnSelect={true}
          onChange={(option) => setMessageOption(option!)}
        />
      </FormControl>
      {messageOption.value === MESSAGE_OPTIONS.HYPE.value && (
        <FormControl isRequired>
          <FormLabel>Include F Bomb</FormLabel>
          <Checkbox
            size="lg"
            colorScheme="red"
            icon={<NuclearBombIcon />}
            name="use-swear-words"
            isChecked={useSwearWords}
            onChange={(e) => setUseSwearWords(e.target.checked)}
          />
        </FormControl>
      )}
      <FormControl isRequired>
        <FormLabel>Delivery Time</FormLabel>
        <Select
          name="delivery-time"
          options={Object.values(DELIVERY_TIME_OPTIONS)}
          value={deliveryTimeOption}
          closeMenuOnSelect={true}
          onChange={(option) => setDeliveryTimeOption(option!)}
        />
      </FormControl>
      <Button
        isLoading={isSubmitting}
        disabled={!phoneNumber || !fullName || !email}
        onClick={(event) => handleSubmit(event)}
      >
        Update Account
      </Button>
      {user.role === "admin" && (
        <Button onClick={(event) => navigate("/admin")}>To Admin</Button>
      )}
      <Text style={{ textAlign: "center" }} color={"gray.500"}>
        Utilizing this service is not a substitute for a qualified mental health
        professional
      </Text>
    </VStack>
  );
};

export default AccountPage;
