import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  InputGroup,
  InputLeftElement,
  useClipboard,
  useColorModeValue,
  VStack,
  Checkbox,
  Text,
  InputLeftAddon,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { BsPerson, BsPhone } from "react-icons/bs";
import { MdOutlineEmail, MdOutlineVpnKey } from "react-icons/md";
import axios from "./utils/axios";
import { useToast } from "@chakra-ui/react";
import { Select } from "chakra-react-select";
import MESSAGE_OPTIONS from "./utils/message.options";
import { useNavigate } from "react-router-dom";
import { useAuth } from "./components/AuthContext";
import { jwtDecode } from "jwt-decode";

export default function ContactFormWithSocialButtons() {
  const { hasCopied, onCopy } = useClipboard("example@example.com");
  const [fullName, setFullName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [messageOption, setMessageOption] = useState(MESSAGE_OPTIONS.HYPE);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [hasViewedDisclaimer, setHasViewedDisclaimer] = useState(false);
  const [successfullySignedUp, setSuccessfullySignedUp] = useState(false);
  const toast = useToast();
  const navigate = useNavigate();
  const { updateUser } = useAuth();

  return (
    <Flex align="center" justify="center" id="contact-form">
      <Box
        borderRadius="lg"
        m={{ base: 5, md: 16, lg: 10 }}
        p={{ base: 1, lg: 16 }}
        w={["100%", 600, 800]}
      >
        <Box w={"100%"}>
          <VStack spacing={{ base: 1, md: 8, lg: 10 }}>
            <Heading
              lineHeight={1.1}
              fontWeight={600}
              fontSize={{ base: "3xl", sm: "5xl", lg: "6xl" }}
              textAlign={"center"}
              paddingBottom={"20px"}
              paddingTop={"20px"}
            >
              I've Heard Enough
            </Heading>

            <Box
              bg={useColorModeValue("white", "gray.700")}
              borderRadius="lg"
              p={8}
              color={useColorModeValue("gray.700", "whiteAlpha.900")}
              shadow="base"
              w="100%"
            >
              {successfullySignedUp && (
                <Text fontSize={15} fontWeight={600}>
                  Thank you for signing up. You should receive your confirmation
                  text momentarily.
                </Text>
              )}
              {!successfullySignedUp && (
                <VStack spacing={5}>
                  <FormControl isRequired>
                    <FormLabel>Name</FormLabel>
                    <InputGroup>
                      <InputLeftElement children={<BsPerson />} />
                      <Input
                        value={fullName}
                        type="text"
                        name="name"
                        id={"contact-form-name"}
                        placeholder="Your Name"
                        onChange={(e) => setFullName(e.target.value)}
                      />
                    </InputGroup>
                  </FormControl>

                  <FormControl isRequired>
                    <FormLabel>Email</FormLabel>
                    <InputGroup>
                      <InputLeftElement children={<MdOutlineEmail />} />
                      <Input
                        value={email}
                        type="email"
                        name="email"
                        placeholder="Your Email"
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </InputGroup>
                  </FormControl>

                  <FormControl isRequired>
                    <FormLabel>Password</FormLabel>
                    <InputGroup>
                      <InputLeftElement children={<MdOutlineVpnKey />} />
                      <Input
                        value={password}
                        type="password"
                        name="password"
                        placeholder="Your Password"
                        onChange={(e) => setPassword(e.target.value)}
                      />
                    </InputGroup>
                  </FormControl>

                  <FormControl isRequired>
                    <FormLabel>Phone Number (US Only)</FormLabel>
                    <InputGroup>
                      <InputLeftAddon children="+1" />
                      <Input
                        value={phoneNumber}
                        type="tel"
                        name="phone"
                        placeholder="Your Phone Number"
                        onChange={(e) => setPhoneNumber(e.target.value)}
                      />
                    </InputGroup>
                  </FormControl>
                  <FormControl isRequired>
                    <FormLabel>Kind of Message</FormLabel>
                    <Select
                      name="message-type"
                      options={Object.values(MESSAGE_OPTIONS)}
                      value={messageOption}
                      closeMenuOnSelect={true}
                      onChange={(option) => setMessageOption(option!)}
                    />
                  </FormControl>
                  {messageOption.value === MESSAGE_OPTIONS.HYPE.value && (
                    <Text>
                      <span style={{ color: "red" }}>F Bombs</span> can be
                      enabled in your profile after account creation
                    </Text>
                  )}
                  <Flex>
                    <Checkbox
                      style={{ paddingRight: 4 }}
                      isChecked={hasViewedDisclaimer}
                      onChange={() =>
                        setHasViewedDisclaimer(!hasViewedDisclaimer)
                      }
                    >
                      I have viewed
                    </Checkbox>
                    <Text
                      color={"blue.400"}
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        window.open(
                          "https://www.freeprivacypolicy.com/live/42d19867-99a7-4307-81ca-27f7441f5770",
                          "_blank"
                        )
                      }
                    >
                      Disclaimer
                    </Text>
                  </Flex>
                  <Button
                    colorScheme="blue"
                    bg="blue.400"
                    color="white"
                    _hover={{
                      bg: "blue.500",
                    }}
                    isLoading={isSubmitting}
                    disabled={
                      !phoneNumber ||
                      !fullName ||
                      !email ||
                      !hasViewedDisclaimer
                    }
                    onClick={() => {
                      setIsSubmitting(true);
                      axios
                        .post("/api/account/create", {
                          phoneNumber,
                          fullName,
                          email,
                          messageTypes: [messageOption.value],
                          password,
                        })
                        .then((response) => {
                          const token = response.data.token;
                          const user = jwtDecode(token);
                          sessionStorage.setItem("user", JSON.stringify(user));
                          sessionStorage.setItem("token", token);
                          setIsSubmitting(false);
                          setSuccessfullySignedUp(true);
                          updateUser(user);
                          window.scrollTo(0, 0);
                          navigate("/account");
                          toast({
                            position: "top",
                            title: "Sign up successful.",
                            description:
                              "We've created your account and signed you up! Select subscription option to get started",
                            status: "success",
                            duration: 9000,
                            isClosable: true,
                          });
                        })
                        .catch((e) => {
                          setIsSubmitting(false);
                          const message =
                            e?.response?.data?.message ||
                            `Check your info and try again.  Fingers crossed`;
                          toast({
                            position: "top",
                            title: "Well that didn't work",
                            description: message,
                            status: "error",
                            duration: 9000,
                            isClosable: true,
                          });
                        });
                    }}
                  >
                    Sign Me Up
                  </Button>
                  <Text style={{ textAlign: "center" }} color={"gray.500"}>
                    Utilizing this service is not a substitute for a qualified
                    mental health professional
                  </Text>
                </VStack>
              )}
            </Box>
          </VStack>
        </Box>
      </Box>
    </Flex>
  );
}
